#root {
    display: flex;
    flex-direction: column;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
}

.sidebar {
    position: absolute;
    top: 7.8rem;
    border: 0;
    left: -16rem;
    width: 16rem;
    transition: left 0.3s ease-in-out !important;
    background-color: #1d2024;
    text-align: left;
    flex-direction: column;
}

.sidebar-toggle {
    position: absolute;
    transition: left 0.3s ease-in-out !important;
    top: 0rem;
    left: 16rem;
    height: 80px;
    font-size: 20px !important;
    font-weight: 500 !important;
    width: 4.3rem;
    height: 4.6rem;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    z-index: 1;
    text-align: center !important;
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
    border-radius: 0 10px 10px 0 !important;
    padding-left: 0 !important;
    border-left: 0 !important;
}


.sidebar-toggle:hover {
    background-color: #ee0000 !important;
    border-color: #ee0000 !important;
}

.sidebar.open {
    transition: left 0.3s ease-in-out !important;
    left: 0;
}
  
.sidebar-toggle.open {
    transition: left 0.3s ease-in-out !important;
}

.nav-link {
    color: #fff !important;
}

.nav-link:hover, .nav-link:active {
    color: #ff0000 !important;
}

.video {
    text-align: center;
    margin-bottom: 5rem;
}

iframe {
    width: 300px;
    height: 200px;  
}

h1 {
    text-align: center;
    top: 0;
    font-size: 4rem !important;
    color: #ff0000 !important;
    background-color: #1d2024;
}

h2 {
    font-size: 20px !important;
    color: #fff !important;
} 

@media only screen and (max-width: 1400px) {
    iframe {
        width: 270px;
        height: 170px;  
    }

    h2 {
        font-size: 20px !important;
    }
}

@media only screen and (max-width: 1200px) {
    iframe {
        width: 300px;
        height: 200px;  
    }

    h2 {
        font-size: 23px !important;
    }
}

@media only screen and (max-width: 990px) {
    iframe {
        width: 300px;
        height: 200px;  
    }

    h2 {
        font-size: 25px !important;
    }
}

@media only screen and (max-width: 767px) {
    iframe {
        width: 500px;
        height: 320px;  
    }

    h1 {
        font-size: 50px !important;
    }

    h2 {
        font-size: 30px !important;
    }

    .sidebar {
        top: 6.8rem;
    }
}

@media only screen and (max-width: 560px) {
    iframe {
        width: 400px;
        height: 270px;  
    }

    h1 {
        font-size: 40px !important;
    }

    .sidebar {
        top: 6rem;
    }

    .sidebar-toggle {
        font-size: 17px !important;
        font-weight: 500 !important;
        width: 3.8rem;
        height: 4.3rem;
    }

    .video {
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width: 450px) {
    iframe {
        width: 300px;
        height: 200px;  
    }

    h1 {
        font-size: 30px !important;
    }

    h2 {
        font-size: 20px !important;
    }

    .sidebar {
        top: 5.3rem;
    }

    .video {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 350px) {
    iframe {
        width: 200px;
        height: 160px;  
    }

    h1 {
        font-size: 25px !important;
    }

    h2 {
        font-size: 15px !important;
    }

    .sidebar {
        top: 4.9rem;
    }

    .sidebar-toggle {
        font-size: 15px !important;
        font-weight: 600 !important;
        width: 3.5rem;
        height: 4rem;
    }

    .video {
        margin-bottom: 1rem;
    }
}

/**
 * Use the :target pseudo-element to apply
 * styles to the element with the same ID as  
 * the fragment identifier.
 * (e.g. `#target-section`)
 * 
 * The pseudo-element can also be used in
 * conjunction with another selector to 
 * define a variety of target styles.
 * (e.g. `#target-section:target)
 */

:target {
	-webkit-animation: target-fade 1s;
	-moz-animation: target-fade 1s;
	-o-animation: target-fade 1s;
	animation: target-fade 1s;
}

/**
 * Keyframe animation definition
 */

@-webkit-keyframes target-fade {
	from { background-color: red; }
	to { background-color: transparent; }
}

@-moz-keyframes target-fade {
	from { background-color: red; }
	to { background-color: transparent; }
}

@-o-keyframes target-fade {
	from { background-color: red; }
	to { background-color: transparent; }
}

@keyframes target-fade {
	from { background-color: red; }
	to { background-color: transparent; }
}
